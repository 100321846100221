<template>
    <BaseControl v-bind="$attrs">
        <div style="width: 100%; height: 300px" class="image-preview" @click="onPreviewClick">
            <div class="text-center" v-if="!hasUploadedFile">
                <i style="font-size: 32px" class="fas fa-camera-retro" />
                <div>{{ $l("platon.form_image_upload", "Расмни юкланг") }}</div>
            </div>

            <span
                ref="image"
                class="image-m-preview"
                style="cursor: zoom-in"
                @click="openFilePreviewModal(remoteFile)"
                v-show="hasUploadedFile"
            />

            <div class="p-controls" style="right: 10px">
                <button
                    class="btn btn-primary mr-2"
                    type="button"
                    @click="downloadRemoteFile()"
                    v-if="!isUploading && hasUploadedFile"
                >
                    <i class="fa fa-download"></i>
                </button>

                <button
                    class="btn btn-danger"
                    type="button"
                    @click="deleteUploadedFile()"
                    v-if="!isUploading && hasUploadedFile && !isDisabledOrReadOnly"
                >
                    <i class="fa fa-trash-alt"></i>
                </button>
            </div>

            <div class="p-controls text-center" v-if="isUploading">
                <btn
                    @click.native.stop="cancelUpload()"
                    class="btn-sm btn-danger d-flex justify-content-center"
                    style="width: 80px"
                >
                    <b-spinner
                        variant="light"
                        type="grow"
                        style="width: 13px; height: 13px"
                        label="Spinning"
                    ></b-spinner>
                    <span class="mx-2">{{ uploadProgress }}%</span> <i class="fa fa-times" />
                </btn>
            </div>
        </div>

        <div class="image-upload-modal">
            <div
                v-if="item.accessTo === 'file' || item.accessTo === 'both' || !item.accessTo"
                class="image-upload-modal-action"
                @click="$refs.input.click()"
            >
                {{ $l("platon.form_image_through_file", "Файл орқали") }}
            </div>
            <div
                v-if="item.accessTo === 'camera' || item.accessTo === 'both' || !item.accessTo"
                class="image-upload-modal-action"
                @click="onToggleSelfieModal"
            >
                {{ $l("platon.form_image_through_camera", "Камера орқали") }}
            </div>
        </div>

        <div class="d-none">
            <input ref="input" type="file" :accept="allowedExtensions" @input="previewImage($event.target.files[0])" />
        </div>
        <ImageUploadByCamera
            @on-toggle-selfie-modal="onToggleSelfieModal"
            @submit-selfie-photo="onSubmitSelfiePhoto"
            v-if="selfieCameraModal"
        />
    </BaseControl>
</template>

<script>
import { isMobile } from "@Platon/core/helpers"
import UploadMixin from "@Platon/components/form/controls/UploadMixin"
import BaseControl from "@Platon/components/form/controls/BaseControl.vue"
import InputControlMixin from "@Platon/mixins/InputControlMixin"
import ImageUploadByCamera from "./ImageUploadByCamera"
import Btn from "@Platon/components/extended/Btn.vue"
import ImageUploadMixin from "@Platon/components/form/controls/ImageUploadMixin"
import FilePreviewMixin from "@Platon/components/misc/preview/FilePreviewMixin"
import { limitFileSizeErrorToast, sizeKbToByte } from "@Platon/components/form/controls/UploadMultipleLimitsMixin"
import ImageControlCropMixin from "@Platon/components/form/controls/ImageControlCropMixin"
import { base64ToFile } from "@Platon/core/helpers/FileHelpers"

export default {
    name: "ImageUploadSingle",
    data() {
        return {
            selfieCameraModal: false
        }
    },
    components: {
        Btn,
        BaseControl,
        ImageUploadByCamera
    },

    mixins: [UploadMixin, InputControlMixin, ImageUploadMixin, FilePreviewMixin, ImageControlCropMixin],
    methods: {
        isMobileOrTablet() {
            const userAgent = navigator.userAgent.toLowerCase()

            const mobileKeywords = ["mobile", "android", "iphone", "ipad", "ipod", "blackberry", "windows phone"]
            const tabletKeywords = ["tablet", "ipad", "android"]

            const isMobile = mobileKeywords.some((keyword) => userAgent.includes(keyword))
            const isTablet = tabletKeywords.some((keyword) => userAgent.includes(keyword))

            return isMobile || isTablet
        },
        onToggleSelfieModal() {
            if (isMobile()) this.openDeviceCamera()
            else if (this.isMobileOrTablet()) this.openDeviceCamera()
            else this.selfieCameraModal = !this.selfieCameraModal
        },
        onSubmitSelfiePhoto(image) {
            this.onImageSelected(image)
            this.remoteFile = image
        },
        /**
         * Preview image, show user if that person satisfies with its profile/image
         * @param {File} image
         */
        previewImage(image) {
            if (!image) return null
            if (image.size > sizeKbToByte(this.item.maxSize)) {
                limitFileSizeErrorToast(this.item.maxSize)
                return null
            }

            this.$modal.show(
                {
                    template: `
					<PModal :footer-actions="modalActions">
                        <template #title>Расм</template>
                        <img ref="image" class="border" style="width: 100%"/>
                        <template #title>Расм</template>
                        <img ref="image" class="border" style="width: 100%"/>
					</PModal>
				`,

                    props: {
                        file: {
                            required: true,
                            type: File
                        },

                        onSave: {
                            required: true,
                            type: Function
                        }
                    },

                    computed: {
                        modalActions() {
                            return [
                                {
                                    text: this.$l("platon.save", "Сақлаш"),
                                    handler: () => {
                                        this.onSave(this.file)
                                    }
                                },
                                { text: this.$l("platon.cancel", "Бекор қилиш") }
                            ]
                        }
                    },

                    mounted() {
                        let reader = new FileReader()
                        reader.onload = (e) => {
                            this.$refs.image.src = e.target.result
                        }
                        reader.readAsDataURL(image)
                    }
                },
                { file: image, onSave: this.onImageSelected }
            )
        },

        onPreviewClick() {
            if (this.item.accessTo === "camera") return null
            if (!(this.disabled || this.readonly) && !this.hasUploadedFile) this.$refs.input.click()
        },

        /**
         *
         * @param {File} image
         */
        async onImageSelected(image) {
            const cropOptions = this.getCropOptionsForElement(this.item)

            if (cropOptions) {
                return this.onFileSelected(await this.tryToCrop(image, cropOptions))
            } else {
                return this.onFileSelected(image)
            }
        },

        /**
         * Upload image programmatically
         *
         * @param base64
         * @param filename
         * @param type
         */
        async uploadBase64File(base64, filename = "image.jpg", type = "image/jpeg") {
            return this.onImageSelected(base64ToFile(base64, filename, type))
        },

        openDeviceCamera() {
            const that = this
            const input = createCameraInputForm()
            document.body.appendChild(input)
            input.style.display = "none"
            input.style.visibility = "hidden"
            input.style.width = "0"
            input.click()

            const exportTakenPictureFromDeviceCamera = async (event) => {
                const compressedImage = await this.compressImage(event.target.files[0], 1024 * 1024) // Specify the maximum size in bytes
                that.previewImage(compressedImage)
                input.remove()
            }

            input.addEventListener(
                "change",
                async (event) => {
                    await exportTakenPictureFromDeviceCamera(event)
                },
                false
            )

            function createCameraInputForm() {
                const cameraInput = document.createElement("input")
                cameraInput.setAttribute("type", "file")
                cameraInput.setAttribute("accept", "image/*")
                cameraInput.setAttribute("capture", "camera")

                return cameraInput
            }
        },
        compressImage(file, maxSizeInBytes) {
            function getMimeType(fileType) {
                if (fileType === "image/png") {
                    return "image/png"
                } else {
                    return "image/jpeg"
                }
            }

            return new Promise((resolve, reject) => {
                const reader = new FileReader()
                reader.onload = function (event) {
                    const img = new Image()
                    img.onload = function () {
                        const canvas = document.createElement("canvas")
                        const ctx = canvas.getContext("2d")
                        canvas.width = img.width
                        canvas.height = img.height
                        ctx.drawImage(img, 0, 0)

                        let compressedDataUrl
                        let quality = 1.0
                        do {
                            compressedDataUrl = canvas.toDataURL(getMimeType(file.type), quality)
                            quality -= 0.1
                        } while (compressedDataUrl.length > maxSizeInBytes && quality > 0.1)

                        fetch(compressedDataUrl)
                            .then((res) => res.blob())
                            .then((blob) => {
                                const compressedFile = new File([blob], file.name, { type: file.type })
                                resolve(compressedFile)
                            })
                            .catch((error) => reject(error))
                    }
                    img.src = event.target.result
                }
                reader.readAsDataURL(file)
            })
        }
    },

    mounted() {
        if (this.$refs.image && this.info && this.info.file) this.previewImage(this.info.file)
        if (Array.isArray(this.item.data) && this.item.data.length > 0) this.remoteFile = this.item.data[0]
    }
}
</script>

<style lang="scss" scoped>
.image-preview {
    overflow: hidden;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    // background: var(--pl-form-image-upload-bg);
    border-radius: 4px;
    background: var(--pl-form-image-upload-bg-hover);
    transition: 0.3s;
    cursor: pointer;

    &:hover {
        opacity: 0.6;
    }
}

.p-controls {
    bottom: 10px;
    position: absolute;

    & .btn {
        width: 40px;
    }
}

.image-upload-modal {
    margin-top: 0.5rem;
    display: flex;
    gap: 10px;

    &-action {
        width: 100%;
        color: #fff;
        padding: 5px 6px;
        border-radius: 6px;
        text-align: center;
        text-transform: capitalize;
        background-color: var(--pl-btn-primary);
        border-color: var(--pl-btn-primary);
        cursor: pointer;
        overflow: hidden;

        text-overflow: ellipsis;
        display: -moz-box;
        -moz-box-orient: vertical;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        line-clamp: 1;
        box-orient: vertical;

        &:hover {
            opacity: 0.9;
        }
        &:active {
            opacity: 1.5;
        }
    }
}
</style>
